<!-- 编辑楼栋单元信息 -->
<template>
  <div class="addld">
    <!-- 头部 -->
    <div class="header">
      <div class="span1" @click="callback">
        <img src="../../../../../../../assets/community/rollback.png" alt="" />
        <span>返回上层</span>
      </div>
      <div class="span2">
        <img
          src="../../../../../../../assets/community/locationSearch.png"
          alt=""
        />
        <!-- <span>华容区</span>
        <span>/</span>
        <span>柴电镇</span>
        <span>/</span> -->
        <span class="name">{{ this.gridName }}</span>
      </div>
    </div>
    <div class="container">
      <div class="top">
        <div class="imr1">
          <div class="oneimg"><a-icon type="check" /></div>
          <span class="title1"
            >&nbsp;&nbsp;&nbsp;填写楼栋基本信息&nbsp;&nbsp;&nbsp;</span
          >
        </div>
        <div class="horizontal-line"></div>
        <div class="imr2">
          <div class="twoimg">2</div>
          <span class="title2"
            >&nbsp;&nbsp;&nbsp;编辑楼栋单元信息&nbsp;&nbsp;&nbsp;</span
          >
        </div>
      </div>
      <div class="conter">
        <div class="conter-img" v-for="unit in units" :key="unit.index">
          <div class="formBox">
            <div class="header-title">
              <div class="title">&nbsp;{{ unit.index }}单元信息</div>
            </div>
            <ax-form
              ref="formBox"
              :formBuilder="formBuilder"
              @change="onFormChange"
            />
            <div class="floor">
              <div style="margin-top: 2%; margin-bottom: 1%">
                <span class="housetype" @click="ChatGPT(unit.index)"
                  >一键生成户型</span
                >
                <span class="qingkon" @click="empty(unit.index)">清空</span>
              </div>
              <div v-if="unit.showCollapse">
                <a-collapse>
                  <a-collapse-panel
                    v-for="(floor, index) in unit.generatedFloors"
                    :key="index"
                  >
                    <template #header>
                      <div style="display: flex">
                        <div class="headertitle">{{ floor.floorName }}</div>
                        <div class="itemlenth">
                          <span>合计{{ floor.plate.length }}户</span>
                          <span class="addtitle" @click="addNewUnit(floor)"
                            >新增户</span
                          >
                        </div>
                      </div>
                    </template>
                    <div class="collapse-container">
                      <div
                        class="collapse"
                        v-for="(unitFloor, unitFloorIndex) in floor.plate"
                        :key="unitFloorIndex"
                      >
                        {{ unitFloor
                        }}<a-icon
                          class="close-icon"
                          type="close"
                          @click="del(unit.index, index, unitFloorIndex)"
                        />
                      </div>
                    </div>
                  </a-collapse-panel>
                </a-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="btn">
        <a-button>取消</a-button>
        <a-button type="primary" @click="keep"> 保存 </a-button>
        <a-button type="primary" @click="callback"> 返回上一步 </a-button>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../api";
const formList = [
  {
    label: "起始楼层",
    type: "inputNumber",
    model: "initialFloor",
    options: {
      showTime: true,
      placeholder: "请输入起始楼层",
    },
    col: { span: 12 },
    rules: [
      {
        required: true,
        pattern: /^-?\d{1,3}$/,
        message: "请输入负数或正整数",
      },
    ],
  },
  {
    label: "最高楼层",
    type: "inputNumber",
    model: "topFloor",
    options: {
      showTime: true,
      placeholder: "请输入最高楼层",
    },
    col: { span: 12 },
    rules: [
      {
        required: true,
        pattern: /^-?\d{1,3}$/,
        message: "请输入正整数",
      },
    ],
  },
  {
    label: "每层户数",
    type: "inputNumber",
    model: "perFloor",
    options: {
      showTime: true,
      placeholder: "请输入每层户数",
    },
    col: { span: 12 },
    rules: [
      {
        required: true,
        pattern: /^-?\d{1,3}$/,
        message: "请输入正整数",
      },
    ],
  },
  {
    label: "起始户数",
    type: "inputNumber",
    model: "initialPlate",
    options: {
      showTime: true,
      placeholder: "请输入起始户数",
    },
    col: { span: 12 },
  },
];
export default {
  data() {
    return {
      api,
      top: "",
      per: "",
      Plate: "",
      initial: "",
      level: "",
      block: "",
      unit: "",
      name: "",
      gridId: "",
      gridName: "",
      latitude: "",
      longitude: "",
      units: [], // 存储所有单元的信息
      generatedFloors: [],
      customSelectionRows: [],
      showCollapse: false,
      modalVisible: false,
      dialogVisible: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 80 },
        { col: { span: 8 } }
      ),
    };
  },
  computed: {},
  created() {},
  methods: {
    getlist() {
      this.gridName = this.$route.query.gridName;
      this.block = this.$route.query.blockCode;
      this.unit = this.$route.query.unitNumber;
      this.cellId = this.$route.query.cellId;
      console.log(this.cellId);
      this.houseType = this.$route.query.houseType;
      this.managerName = this.$route.query.managerName;
      this.managerPhone = this.$route.query.managerPhone;
      this.initializeUnits();
    },
    initializeUnits() {
      const unitCount = parseInt(this.unit);
      this.units = []; // 重置单元数组
      for (let i = 1; i <= unitCount; i++) {
        this.units.push({
          index: i,
          generatedFloors: [],
          showCollapse: false,
        });
      }
    },
    //ax-from的change事件
    onFormChange(e, type) {
      if (type === "initialFloor") {
        this.initial = e;
      } else if (type === "topFloor") {
        this.top = e;
      } else if (type === "perFloor") {
        this.per = e;
      } else if (type === "initialPlate") {
        this.Plate = e;
      }
    },
    // 一键生成
    ChatGPT(unitIndex) {
      const unit = this.units.find((u) => u.index === unitIndex);
      if (unit) {
        if (this.initial === null || this.top === null || this.per === null) {
          this.$message.error("请确保所有必填项已填写");
          return;
        }
        // 根据输入值生成相应的户型
        unit.generatedFloors = [];
        const startFloor = parseInt(this.initial, 10); // 起始楼层
        const endFloor = parseInt(this.top, 10); // 最高楼层
        const perFloor = parseInt(this.per, 10); // 每层户数
        for (let i = startFloor; i <= endFloor; i++) {
          if (i === 0) continue; // 跳过 0 楼
          const units = this.getUnits(i, perFloor);
          unit.generatedFloors.push({
            floorName: i + "楼", //楼层
            total: perFloor, //总数
            plate: units, //所有门牌号
          });
        }
        // 打印点击的一键生成的单元信息
        console.log(`生成的单元信息：${unitIndex}单元信息`);
        console.log(unit.generatedFloors);
        unit.showCollapse = true; // 仅显示当前单元的<a-collapse>
      }
    },

    // 户型逻辑
    getUnits(floorNumber, unitCount) {
      const units = [];
      for (let j = 1; j <= unitCount; j++) {
        const room = `${floorNumber}${j < 10 ? "0" + j : j}`;
        units.push(room);
      }
      return units;
    },
    // 添加新户型
    addNewUnit(floor) {
      const floorNumber = parseInt(floor.floorName, 10); // 将楼层名称转换为数字
      if (!isNaN(floorNumber)) {
        let maxUnitNumber = null;

        // 检查并找到最大户数，无论是正数还是负数
        floor.plate.forEach((unit) => {
          const unitNumber = parseInt(unit.slice(`${floorNumber}`.length), 10);
          if (maxUnitNumber === null || unitNumber > maxUnitNumber) {
            maxUnitNumber = unitNumber;
          }
        });
        // 处理负数和正数的逻辑
        let newUnitNumber = null;
        if (maxUnitNumber < 0) {
          // 负数情况下，生成新的户型编号
          newUnitNumber = `${floorNumber}${String(maxUnitNumber - 1).padStart(
            2,
            "0"
          )}`;
        } else {
          // 正数情况下，生成新的户型编号
          newUnitNumber = `${floorNumber}${String(maxUnitNumber + 1).padStart(
            2,
            "0"
          )}`;
        }
        floor.plate.push(newUnitNumber); // 添加新的户型编号
      } else {
        console.error("Invalid floor number:", floor.floorName);
      }
    },

    // 清空
    empty(unitIndex) {
      const unit = this.units.find((u) => u.index === unitIndex);
      if (unit) {
        // 清空操作
        unit.generatedFloors = [];
        unit.showCollapse = false; // 隐藏<a-collapse>
      }
    },
    // 删除对应户型
    del(unitIndex, floorIndex, unitFloorIndex) {
      const unit = this.units.find((u) => u.index === unitIndex);
      if (unit) {
        const deletedUnit =
          unit.generatedFloors[floorIndex].plate[unitFloorIndex];
        console.log("删除的户型信息：", deletedUnit);
        unit.generatedFloors[floorIndex].plate.splice(unitFloorIndex, 1);
      }
    },
    //保存
    keep() {
      // this.$router.push({
      //   path: "/views/communityGovernance/housingList/buildingList/Management",
      // });
    },
    //返回
    callback() {
      this.$router.push({
        path: "/views/communityGovernance/housingList/buildingList/Management/components/add.vue",
      });
    },
  },
  mounted() {
    this.getlist();
  },
};
</script>
<style  lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
.addld {
  width: 100%;
  height: 100%;
}
.header {
  width: 100%;
  // height: 0.15rem;
  background: rgba(188, 204, 255, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.span1 {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 1%;
  img {
    margin-right: 0.02rem;
  }
}
.span2 {
  display: flex;
  align-items: center;
  padding-right: 1.5%;
  img {
    margin-right: 0.01rem;
  }
  .name {
    color: #2b5dff;
  }
  span {
    font-weight: 700;
    font-size: 0.1rem;
    color: #2d3138;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-right: 0.01rem;
    white-space: nowrap;
  }
}
.container {
  margin-top: 2%;
}
.top {
  display: flex;
  align-items: center;
  justify-content: center;
}
.horizontal-line {
  width: 35%;
  border-top: 1px solid #909399;
  margin-left: 1%;
  margin-right: 1%;
}
.imr1 {
  display: flex;
  align-items: center;
  .title1 {
    font-weight: 400;
    font-size: 14px;
    color: #909399;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    white-space: nowrap;
  }
}
.imr2 {
  display: flex;
  align-items: center;
  .title2 {
    font-weight: 700;
    font-size: 14px;
    color: #2d3138;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    white-space: nowrap;
  }
}
.oneimg {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.08rem;
  width: 0.2rem;
  height: 0.2rem;
  color: #2a5cff;
  border: 1px solid #2a5cff;
  border-radius: 50%; /* 使用 border-radius 来创建圆形 */
}
.twoimg {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.08rem;
  color: #ffffff;
  width: 0.2rem;
  height: 0.2rem;
  background-color: #2b5dff;
  border-radius: 50%; /* 使用 border-radius 来创建圆形 */
}
.conter {
  margin-top: 2%;
  display: flex;
  align-items: center;
  // justify-content: center;
  // height: 2rem;
  flex-direction: column;
}
.footer {
  margin-top: 2%;
  margin-bottom: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.btn {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
.btn .ant-btn {
  margin: 0 10px; /* 在按钮的左右分别添加 10px 的间距 */
}
.title {
  border-left: 4px solid #556bff;
  font-weight: 700;
  font-size: 14px;
  color: #2d3138;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.conter-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-bottom: 2%;
}
.header-title {
  margin-bottom: 2%;

  background: #f2f5ff;
  border-radius: 0px;
}
.gridname {
  position: relative;
  width: 100%;
  height: 32px;
  border: 1px solid #d9d9d9;
  text-align: left;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  .gridspan {
    position: absolute;
    left: 11px;
    color: #bfbfbf;
    pointer-events: none;
  }
}
.gridname2 {
  position: relative;
  width: 100%;
  height: 32px;
  border: 1px solid #d9d9d9;
  text-align: left;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
}
.collapse-container {
  display: flex;
  flex-wrap: wrap; /* 让元素自动换行 */
}
.collapse {
  width: 0.4rem;
  height: 0.2rem;
  border-radius: 6px;
  background-color: #f0f0f0;
  margin-right: 8px;
  margin-bottom: 8px;
  display: flex; /* 让内容垂直居中 */
  justify-content: space-around;
  align-items: center;
}

.close-icon {
  margin-left: 5px;
  cursor: pointer;
}
.housetype {
  font-weight: 700;
  font-size: 12px;
  color: #e6a23c;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-right: 2%;
}
.qingkon {
  font-weight: 700;
  font-size: 12px;
  color: #2d3138;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.addtitle {
  font-weight: 400;
  font-size: 0.07rem;
  color: #556bff;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headertitle {
  font-weight: 700;
  font-size: 0.06rem;
  color: rgb(45, 49, 56);
  display: flex;
  justify-content: center;
  align-items: center;
}
.itemlenth {
  display: flex;
  justify-content: space-between;
  width: 91%;
  margin-left: 5%;
}
</style>